.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

[data-sticky] {
  width:100%;
}

.sticky-container {
  z-index: 5;
}
